import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Merge, PropsType } from '../../../types/UtilityTypes';
import { useTheme } from '@mui/material';
import { BasicContainer } from './BasicContainer';

export type BobTabContentProps = {
    children?: React.ReactNode;
    index: number;
    currentIndex: number;
};

export type BobTabProps = Merge<
    PropsType<typeof Tab>,
    {
        tabTitle: string;
        index: number;
    }
>;

export type BobTabCollectionProps = Merge<
    PropsType<typeof Tabs>,
    {
        children: React.ReactNode;
        currentIndex: number;
        handleTabChange: (
            event: React.SyntheticEvent,
            newValue: number,
        ) => void;
    }
>;

export const BobTabCollection = (props: BobTabCollectionProps) => {
    const { children, currentIndex, handleTabChange, ...rest } = props;

    return (
        <BasicContainer>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    value={currentIndex}
                    onChange={handleTabChange}
                    {...rest}
                    sx={{ m: 0 }}
                >
                    {children}
                </Tabs>
            </Box>
        </BasicContainer>
    );
};

export const BobTab = (props: BobTabProps) => {
    const { tabTitle, index, ...rest } = props;

    return (
        <Tab
            label={tabTitle}
            value={index}
            id={`bobtab-${tabTitle}-${index}`}
            {...rest}
        />
    );
};

export const BobTabContent = (props: BobTabContentProps) => {
    const { children, index, currentIndex } = props;
    const theme = useTheme();

    return (
        <BasicContainer
            role="tabpanel"
            hidden={index !== currentIndex}
            id={`bob-tab-content-${index}`}
            sx={{ display: 'flex' }}
        >
            {currentIndex === index && (
                <BasicContainer sx={{ padding: theme.spacing(3) }}>
                    {children}
                </BasicContainer>
            )}
        </BasicContainer>
    );
};
