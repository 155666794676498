import { useMemo } from 'react';
import ThemeProvider, { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import { CreateBobTheme } from './bobTheme';

// Omitting 'theme' from ThemeProviderProps because otherwise it asks for the default theme everytime it's used.
type BobThemeProps = Omit<ThemeProviderProps, 'theme'>;

const BobThemeProvider = (props: BobThemeProps) => {
    const { ...rest } = props;

    const bobTheme = useMemo(() => CreateBobTheme(), []);

    return <ThemeProvider {...rest} theme={bobTheme} />;
};

export default BobThemeProvider;
