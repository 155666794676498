import {
    Alert,
    Button,
    FormControl,
    Grid2,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { BobTab, BobTabCollection } from '../../common/containers/BobTabs';
import { useEffect, useState } from 'react';
import { FundingRunMainTabContent } from './MainTab/FundingRunMainTabContent';
import { MoreVertRoundedIcon } from '../../common/assets/MoreVertRoundedIcon';
import { ExpandMoreRoundedIcon } from '../../common/assets/ExpandMoreRoundedIcon';
import { VerificationSummaryTabContent } from './VerificationTab/Summary/VerificationTab';
import { bobColors } from '../../../styles/colors';
import { BasicContainer } from '../../common/containers/BasicContainer';
import FmRunDialog from './FmRunDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fmRunIdStateAtom } from '../../../store/fmRun/fmRunIdAtom';
import {
    OptionalBoolean,
    OptionalNumber,
    OptionalString,
} from '../../../types/UtilityTypes';
import { random } from 'lodash';
import { stagingClientListAtom } from '../../../store/fmRun/staging/stagingClientListAtom';
import { StagingTab } from './StagingTab/StagingTab';
import { VerificationDetailTabContent } from './VerificationTab/Detail/VerificationDetailTab';
import { tenantStateAtom } from '../../../store/tenant/tenantAtom';

export const FundingRunPageIcon = createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4C4 19 4 19.1 4 18H21V7C22.1 7 22 7 23 7Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>,
    'Funding Run',
);

const FundingRunPage = () => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const [selectedClients, setSelectedClients] = useRecoilState(
        stagingClientListAtom,
    );

    const currentTenant = useRecoilValue(tenantStateAtom);

    const [stagingSubmitSuccess, setStagingSubmitSuccess] =
        useState<boolean>(false);

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        setCurrentIndex(newValue);
    }

    const mainTab = {
        index: 0,
        label: 'Main',
    };
    const stagingTab = {
        index: 1,
        label: 'Staging',
    };
    const verificationDetailTab = {
        index: 2,
        label: 'Verification',
    };
    const verificationSummaryTab = {
        index: 3,
        label: 'Other',
    };

    const [fmRunGridDialogOpen, setFmRunGridDialogOpen] = useState(false);
    const handleClose = () => {
        setFmRunGridDialogOpen(false);
    };

    const [anchorMenuEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorMenuEl);

    const [selectedFmRunId, setSelectedFmRunId] =
        useRecoilState(fmRunIdStateAtom);

    const [unpostAlertSuccess, setUnpostAlertSuccess] =
        useState<OptionalBoolean>(null);
    const [errorMessage, setErrorMessage] = useState<OptionalString>(null);

    function unpostRun(selectedFmRunId: OptionalNumber) {
        //
        //  The unposting process...
        //
        //  The user can only un-post if an FM run is selected. The menu option is disabled if one isn't selected, so this function shouldn't even get hit in that case.
        //  If one is selected, this function will make an API call. The response success/failure will be stored in the "responseSuccess" variable.
        //  If the response returns successfully:
        //      --It will create a success alert.
        //      --It will also start a 5 second timer which will clear the selected run and then clear the responseSuccess value (which will also in turn hide the alert)
        //  If the response returns as a failure with an error message, it will pop-up an error alert.
        //      --It will not be on a timer, so the user can take a screenshot for troubleshooting if necessary.
        //      --It will not clear the selected run in case they want to do anything further with it or clicked it on accident.
        //

        if (!!selectedFmRunId) {
            // TODO add API call -- until then, check if random number is even/odd to determine success/failure
            const responseSuccess = random() % 2 ? true : false;
            setMenuAnchorEl(null);
            setUnpostAlertSuccess(responseSuccess);
            if (!responseSuccess) {
                // TODO get error message
                setErrorMessage('400/500 error goes here');
            }
            if (responseSuccess) {
                // Null out the selectedFmRunId after 5 seconds.
                window.setTimeout(() => {
                    setSelectedFmRunId(null);
                    setUnpostAlertSuccess(null);
                }, 5000);
            }
        }
    }

    const theme = useTheme();

    useEffect(() => {
        return () => {
            setStagingSubmitSuccess(false);
            setSelectedClients([]);
            setFmRunGridDialogOpen(false);
        };
    }, [currentIndex, currentTenant]);

    useEffect(() => {
        return () => {
            setSelectedFmRunId(null);
            setCurrentIndex(0);
        };
    }, [currentTenant]);

    return (
        <BasicContainer>
            <Grid2
                container
                size={12}
                sx={{
                    alignItems: 'top',
                    justifyItems: 'space-between',
                    margin: theme.spacing(3.5),
                    height: theme.spacing(5),
                }}
            >
                <Grid2 size={3} justifyContent={'start'} display={'flex'}>
                    <Typography
                        variant="h2"
                        fontSize={24}
                        whiteSpace={'pre-wrap'}
                    >
                        Funding Run{' '}
                    </Typography>
                    {!!selectedFmRunId &&
                        (currentIndex === mainTab.index ||
                            currentIndex === verificationDetailTab.index) && (
                            <Typography
                                variant="h2"
                                fontSize={24}
                                color={bobColors.coolGrey80.value}
                            >
                                {'('}
                                {selectedFmRunId}
                                {')'}
                            </Typography>
                        )}
                </Grid2>
                <Grid2 size={1}></Grid2>
                <Grid2
                    container
                    size={8}
                    columnGap={theme.spacing(1)}
                    justifyContent={'end'}
                    alignItems={'bottom'}
                    columnSpacing={theme.spacing(1)}
                >
                    <Grid2
                        size={4}
                        flexBasis={'max-content'}
                        flexDirection={'row-reverse'}
                    >
                        {currentIndex === mainTab.index && (
                            <FormControl
                                fullWidth
                                sx={{
                                    backgroundColor: bobColors.white100.value,
                                }}
                            >
                                <InputLabel id="Select-Employer" size="small">
                                    Employer
                                </InputLabel>
                                <Select
                                    label="Employer"
                                    labelId="Select-Employer"
                                    variant={'outlined'}
                                    value="An Example Employer"
                                    IconComponent={ExpandMoreRoundedIcon}
                                    sx={{
                                        backgroundColor:
                                            bobColors.white100.value,
                                        textAlign: 'center',
                                        height: theme.spacing(4.75),
                                        width: theme.spacing(25),
                                    }}
                                >
                                    <MenuItem
                                        value="An Example Employer"
                                        sx={{
                                            backgroundColor:
                                                bobColors.white100.value,
                                        }}
                                    >
                                        <Typography fontSize={14}>
                                            An Example Employer
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid2>
                    <Grid2
                        size={3}
                        flexBasis={'max-content'}
                        flexDirection={'row'}
                    >
                        {(currentIndex === mainTab.index ||
                            currentIndex === verificationDetailTab.index) && (
                            <Button
                                variant="outlined"
                                sx={{
                                    backgroundColor: bobColors.white100.value,
                                    width: theme.spacing(20),
                                }}
                                onClick={() =>
                                    setFmRunGridDialogOpen(!fmRunGridDialogOpen)
                                }
                            >
                                Choose FM Run
                            </Button>
                        )}
                        {currentIndex === stagingTab.index && (
                            <Button
                                variant="outlined"
                                disabled={
                                    selectedClients.length == 0 ||
                                    stagingSubmitSuccess === true
                                }
                                onClick={
                                    /*TODO hook up to form and API when that is functional*/ () => {
                                        setStagingSubmitSuccess(true);
                                    }
                                }
                                sx={{
                                    backgroundColor: bobColors.white100.value,
                                    width: theme.spacing(24),
                                }}
                            >
                                Stage & Verify Runs
                            </Button>
                        )}
                    </Grid2>
                    <Grid2 flexBasis={'max-content'} flexDirection={'row'}>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: bobColors.white100.value,
                                minWidth: theme.spacing(1),
                                width: theme.spacing(1),
                            }}
                            onClick={(event) => {
                                setMenuAnchorEl(event?.currentTarget);
                            }}
                        >
                            <MoreVertRoundedIcon />
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorMenuEl}
                            keepMounted
                            open={menuOpen}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={() => {
                                    unpostRun(selectedFmRunId);
                                }}
                                key="unpostRun"
                                disabled={selectedFmRunId === null}
                            >
                                Un-post Run
                            </MenuItem>
                        </Menu>
                    </Grid2>
                </Grid2>
            </Grid2>
            <BobTabCollection
                currentIndex={currentIndex}
                handleTabChange={handleTabChange}
            >
                <BobTab index={mainTab.index} tabTitle={mainTab.label} />
                <BobTab index={stagingTab.index} tabTitle={stagingTab.label} />
                <BobTab
                    index={verificationDetailTab.index}
                    tabTitle={verificationDetailTab.label}
                />
                <BobTab
                    index={verificationSummaryTab.index}
                    tabTitle={verificationSummaryTab.label}
                />
            </BobTabCollection>

            <FmRunDialog
                isOpen={fmRunGridDialogOpen}
                handleClose={handleClose}
            />
            <FundingRunMainTabContent
                index={mainTab.index}
                currentIndex={currentIndex}
            />
            <StagingTab
                index={stagingTab.index}
                currentIndex={currentIndex}
                stagingSubmitSuccess={stagingSubmitSuccess}
            />
            <VerificationDetailTabContent
                index={verificationDetailTab.index}
                currentIndex={currentIndex}
            />
            <VerificationSummaryTabContent
                index={verificationSummaryTab.index}
                currentIndex={currentIndex}
            />
            <BasicContainer sx={{ p: theme.spacing(4) }}>
                {unpostAlertSuccess === true && !!selectedFmRunId && (
                    <Alert
                        severity="success"
                        onClose={() => {
                            setUnpostAlertSuccess(null);
                        }}
                    >
                        Successfully unposted FmRunId {selectedFmRunId}.
                        De-selecting run....
                    </Alert>
                )}
                {unpostAlertSuccess === false && (
                    <Alert
                        severity="error"
                        onClose={() => {
                            setUnpostAlertSuccess(null);
                        }}
                    >
                        Unposting failed. An error occurred: {errorMessage}
                    </Alert>
                )}
            </BasicContainer>
        </BasicContainer>
    );
};

export default FundingRunPage;
