import { ClientSearchRequest, ClientSearchResultList } from '../api/clientList';

export const mockClientRequest: ClientSearchRequest = {
    TenantName: 'paperless',
};

export const mockClientSearchResultList: ClientSearchResultList = {
    data: [
        {
            EINC: 444,
            ClientName: 'Abcd',
            TenantName: 'Paperless',
            ClientTypeId: 4,
            ClientTypeName: 'Funding',
        },
        {
            EINC: 555,
            ClientName: 'efgh',
            TenantName: 'Paperless',
            ClientTypeId: 4,
            ClientTypeName: 'Funding',
        },
        {
            EINC: 666,
            ClientName: 'hijk',
            TenantName: 'Paperless',
            ClientTypeId: 4,
            ClientTypeName: 'Funding',
        },
        {
            EINC: 777,
            ClientName: 'lmno',
            TenantName: 'Paperless',
            ClientTypeId: 4,
            ClientTypeName: 'Funding',
        },
    ],
    totalCount: 4,
};
