import {
    BobTabContent,
    BobTabContentProps,
} from '../../../../common/containers/BobTabs';
import { useCallback, useEffect, useState } from 'react';
import {
    mockFmRunVerificationRequest,
    mockFmRunVerificationResultList,
} from '../../../../../mockData/mockFmRunVerificationResultList';
import {
    OptionalBoolean,
    OptionalString,
} from '../../../../../types/UtilityTypes';
import { BasicContainer } from '../../../../common/containers/BasicContainer';
import {
    Alert,
    AlertProps,
    Button,
    Grid2,
    Snackbar,
    Typography,
    useTheme,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { VerificationDetailDataGrid } from './VerificationDetailDataGrid';
import { VerificationDetailMessageLogDataGrid } from './VerificationDetailMessageLogDataGrid';
import VerificationRunDetailResult, {
    VerificationRunDetailRequest,
} from '../../../../../api/fmRun/verificationRunDetailResult';
import {
    mockVerificationRunDetailRequest,
    mockVerificationRunDetailResult,
} from '../../../../../mockData/mockVerificationRunDetailResult';
import {
    VerificationRunDetailLogMessageRequest,
    VerificationRunDetailLogMessageResultList,
} from '../../../../../api/fmRun/verificationRunDetailLogMessage';
import {
    mockVerificationRunDetailLogMessageRequest,
    mockVerificationRunDetailLogMessageResultList,
} from '../../../../../mockData/mockVerificationRunDetailLogMessageResultList';

export const VerificationDetailTabContent = (props: BobTabContentProps) => {
    const { currentIndex, index } = props;

    //RunDetail results
    const [verificationRunDetailResult, setFmRunVerificationResult] =
        useState<VerificationRunDetailResult | null>(null);
    const [verificationRunDetailIsLoading, setVerificationRunDetailIsLoading] =
        useState(false);
    const [overrideWasSuccessful, setOverrideWasSuccessful] =
        useState<OptionalBoolean>(null);

    // We should assume the verification failed and doesn't have an override note.
    // If it turns out it doesn't, the child grid has a function that will update this accordingly.
    const [hasRowsRequiringNotes, setHasRowsRequiringNotes] =
        useState<boolean>(true);

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        return setHasRowsRequiringNotes(true);
    }, []);

    const loadNewVerificationDetailResults = useCallback(
        async (request: VerificationRunDetailRequest) => {
            setVerificationRunDetailIsLoading(true);

            const response = await getFakeVerificationDetailResult(request);

            // TODO replace this in a future PR with something like:
            //      if(response.isSuccess)
            if (!!response) {
                setFmRunVerificationResult(response);
            } else {
                alert('This will be an error message');
            }

            setVerificationRunDetailIsLoading(false);
        },
        [],
    );

    // TODO replace this with an actual API call when the API is operational
    async function getFakeVerificationDetailResult(
        fmRunVerificationRequest: VerificationRunDetailRequest,
    ): Promise<VerificationRunDetailResult | null | undefined> {
        return new Promise(function (resolve, reject) {
            if (mockFmRunVerificationResultList) {
                // Setting timeout to simulate loading from the API and display the built-in loading skeleton...
                setTimeout(() => {
                    resolve(
                        mockVerificationRunDetailResult as VerificationRunDetailResult,
                    );
                }, 500);
            } else {
                setTimeout(() => {
                    reject('You should have never seen this' as OptionalString);
                }, 500);
            }
        });
    }

    // TODO replace this with useMutation from @tanstack/react-query
    useEffect(() => {
        loadNewVerificationDetailResults(mockVerificationRunDetailRequest);
    }, [mockFmRunVerificationRequest]);

    //Log Message Results
    const [
        verificationRunDetailLogMessageResults,
        setFmRunVerificationLogMessageResults,
    ] = useState<VerificationRunDetailLogMessageResultList | null>(null);
    const [
        verificationRunDetailLogMessageIsLoading,
        setVerificationRunDetailLogMessageIsLoading,
    ] = useState(false);

    const loadNewVerificationDetailLogMessageResults = useCallback(
        async (request: VerificationRunDetailLogMessageRequest) => {
            setVerificationRunDetailIsLoading(true);

            const response =
                await getFakeVerificationDetailLogMessageResult(request);

            // TODO replace this in a future PR with something like:
            //      if(response.isSuccess)
            if (!!response) {
                setFmRunVerificationLogMessageResults(response);
            } else {
                alert('This will be an error message');
            }

            setVerificationRunDetailLogMessageIsLoading(false);
        },
        [],
    );

    // TODO replace this with an actual API call when the API is operational
    async function getFakeVerificationDetailLogMessageResult(
        fmRunVerificationRequest: VerificationRunDetailLogMessageRequest,
    ): Promise<VerificationRunDetailLogMessageResultList | null | undefined> {
        return new Promise(function (resolve, reject) {
            if (mockFmRunVerificationResultList) {
                // Setting timeout to simulate loading from the API and display the built-in loading skeleton...
                setTimeout(() => {
                    resolve(
                        mockVerificationRunDetailLogMessageResultList as VerificationRunDetailLogMessageResultList,
                    );
                }, 500);
            } else {
                setTimeout(() => {
                    reject('You should have never seen this' as OptionalString);
                }, 500);
            }
        });
    }

    // TODO replace this with an actual API call when the API is operational
    async function updateVerificationRunToOverridden(
        verificationRunDetailPostRequest,
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            setTimeout(() => {
                setOverrideWasSuccessful(true);
                setSnackbar({
                    children: 'Verification successfully overridden',
                    severity: 'success',
                });
                resolve(true), 500;
            });
        });
    }

    // TODO replace this with useMutation from @tanstack/react-query
    useEffect(() => {
        loadNewVerificationDetailLogMessageResults(
            mockVerificationRunDetailLogMessageRequest,
        );
    }, [mockVerificationRunDetailLogMessageRequest]);

    const theme = useTheme();

    function OverrideDisabled(): boolean {
        if (
            verificationRunDetailLogMessageResults !== null &&
            verificationRunDetailLogMessageResults !== undefined
        ) {
            if (
                verificationRunDetailResult![0].OverrideAllowed &&
                !hasRowsRequiringNotes
            ) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    async function handleOverrideClick() {
        await updateVerificationRunToOverridden(mockFmRunVerificationRequest);
    }

    return (
        <BobTabContent currentIndex={currentIndex} index={index}>
            <Grid2
                container
                sx={{
                    my: theme.spacing(2),
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Grid2 size={9}>
                    <Typography variant="h6">Verification Runs</Typography>
                </Grid2>
                <Grid2
                    container
                    size={3}
                    flexDirection={'row-reverse'}
                    columnGap={1}
                >
                    <Button
                        variant="contained"
                        disabled={OverrideDisabled()}
                        sx={{
                            fontSize: 15,
                        }}
                        onClick={() => {
                            handleOverrideClick();
                        }}
                    >
                        Override
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ p: theme.spacing(1), minWidth: 0 }}
                    >
                        <RefreshIcon />
                    </Button>
                </Grid2>
            </Grid2>
            <BasicContainer sx={{ pb: theme.spacing(3) }}>
                <VerificationDetailDataGrid
                    isLoading={verificationRunDetailIsLoading}
                    verificationResultList={verificationRunDetailResult}
                    successfullyOverridden={overrideWasSuccessful}
                />
            </BasicContainer>
            <BasicContainer sx={{ py: theme.spacing(2) }}>
                <Typography variant="h6">Verification Message Log</Typography>
            </BasicContainer>
            <BasicContainer sx={{ pb: theme.spacing(3) }}>
                <VerificationDetailMessageLogDataGrid
                    isLoading={verificationRunDetailLogMessageIsLoading}
                    verificationRunDetailLogMessageResults={
                        verificationRunDetailLogMessageResults
                    }
                    onSaveNote={(requiresNotes) => {
                        setHasRowsRequiringNotes(requiresNotes);
                    }}
                />
            </BasicContainer>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </BobTabContent>
    );
};
