import { useMemo } from 'react';
import { FmRunVerificationResultList } from '../../../../../api/fmRun/fmRunVerificationResult';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { DataGrid } from '@mui/x-data-grid';
import { Chip, Link } from '@mui/material';
import { fmRunVerificationStatusNames } from '../../../../../constants/fmRunVerificationStatusNames';
import { bobColors } from '../../../../../styles/colors';

export type VerificationSummaryDataGridProps = {
    isLoading: boolean;
    fmRunVerificationResultList: FmRunVerificationResultList | null;
};
export const VerificationSummaryDataGrid = (
    props: VerificationSummaryDataGridProps,
) => {
    const { isLoading, fmRunVerificationResultList } = props;

    const resultRows = useMemo(() => {
        return (
            fmRunVerificationResultList?.data?.map((client) => ({
                ...client,
                id: client.EINC,
            })) ?? []
        );
    }, [fmRunVerificationResultList?.data]);

    function determineChipColor(cellValueToLowerCase: string) {
        switch (cellValueToLowerCase) {
            case fmRunVerificationStatusNames.PASS.toLowerCase():
                return bobColors.successMuted.value;
            case fmRunVerificationStatusNames.FAIL.toLowerCase():
                return bobColors.errorMuted.value;
            case fmRunVerificationStatusNames.WARNING.toLowerCase():
                return bobColors.warningMuted.value;
            case fmRunVerificationStatusNames.SKIP.toLowerCase():
                return bobColors.infoMuted.value;
            default:
                return bobColors.successMuted.value;
        }
    }

    const columns = useMemo(
        (): GridColDef<GridValidRowModel>[] => [
            {
                field: 'EINC',
                headerName: 'EINC',
                type: 'number',
                flex: 0.5,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'CompanyFullName',
                headerName: 'Client Name',
                type: 'string',
                flex: 1,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'FmRunId',
                headerName: 'Funding Run ID',
                type: 'number',
                flex: 1,
                headerAlign: 'left',
                align: 'left',
                renderCell: (cell) => {
                    return <Link>{cell.value}</Link>;
                },
            },
            {
                field: 'FmRunVerificationStatusName',
                headerName: 'Run Verification Status',
                type: 'string',
                flex: 0.75,
                headerAlign: 'left',
                align: 'left',
                renderCell: (cell) => {
                    const cellChipColor = determineChipColor(
                        cell.value.toLowerCase(),
                    );

                    return (
                        <Chip
                            variant="filled"
                            label={cell.value}
                            sx={{ backgroundColor: cellChipColor }}
                        />
                    );
                },
            },
            {
                field: 'FmRunVerificationNote',
                headerName: 'Description/Notes',
                type: 'string',
                flex: 2,
                headerAlign: 'left',
                align: 'left',
            },
        ],
        [resultRows],
    );

    return (
        <DataGrid
            autosizeOnMount
            columns={columns}
            loading={isLoading}
            rows={resultRows}
            checkboxSelection
            hideFooter
            disableColumnMenu
        />
    );
};
