import Icon from '@mui/material/Icon/Icon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { bobColors } from '../../../styles/colors';

export type BooleanCircleIconProps = {
    booleanValue: boolean;
};

export const BooleanCircleIcon = ({ booleanValue }: BooleanCircleIconProps) => {
    return (
        <Icon>
            {booleanValue && (
                <CheckCircleOutlineRoundedIcon
                    sx={{
                        fill: bobColors.success100.value,
                    }}
                />
            )}
            {!booleanValue && (
                <CancelOutlinedIcon
                    sx={{
                        fill: bobColors.greyFalse.value,
                    }}
                />
            )}
        </Icon>
    );
};
