import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
} from '@mui/material';
import {
    BobTabContent,
    BobTabContentProps,
} from '../../../common/containers/BobTabs';
import { fmRunIdStateAtom } from '../../../../store/fmRun/fmRunIdAtom';
import { useTheme } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { BasicContainer } from '../../../common/containers/BasicContainer';

export const FundingRunMainTabContent = (props: BobTabContentProps) => {
    const { index, currentIndex } = props;
    const theme = useTheme();
    const [selectedFmRunId, setSelectedFmRunId] =
        useRecoilState(fmRunIdStateAtom);

    return (
        <BobTabContent index={index} currentIndex={currentIndex}>
            <BasicContainer sx={{ pr: 0.5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        mb: theme.spacing(2),
                    }}
                >
                    <Button variant="contained">Start FM Run</Button>
                </Box>

                <Box>
                    {selectedFmRunId && (
                        <Accordion sx={{ width: '100%' }} expanded={true}>
                            <AccordionSummary>Fm Run</AccordionSummary>
                            <AccordionDetails>
                                The current selected FmRunId is{' '}
                                {selectedFmRunId}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Accordion sx={{ width: '100%' }} expanded={true}>
                        <AccordionSummary>Employer Details</AccordionSummary>
                        <AccordionDetails>
                            Employer details go here
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </BasicContainer>
        </BobTabContent>
    );
};
