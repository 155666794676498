import { useTheme } from '@mui/material/styles';
import {
    BobTabContent,
    BobTabContentProps,
} from '../../../common/containers/BobTabs';
import { BasicContainer } from '../../../common/containers/BasicContainer';
import Typography from '@mui/material/Typography';
import Form from '../../../common/form/Form';
import ClientTypeDropdown from '../../../dropdowns/ClientTypeDropdown';
import useFormState from '../../../../utils/formStateUtils';
import {
    Merge,
    OptionalBoolean,
    OptionalNumber,
    OptionalString,
} from '../../../../types/UtilityTypes';
import Grid from '@mui/material/Grid2';
import TextField from '../../../common/form/formControls/TextField';
import { StagingClientListDataGrid } from './StagingClientListDataGrid';
import { useCallback, useEffect, useState } from 'react';
import {
    ClientSearchRequest,
    ClientSearchResultList,
} from '../../../../api/clientList';
import {
    mockClientRequest,
    mockClientSearchResultList,
} from '../../../../mockData/mockClientSearchResultList';
import { Alert, Box, Skeleton, Snackbar } from '@mui/material';
import { bobColors } from '../../../../styles/colors';

export type StagingTabProps = Merge<
    BobTabContentProps,
    { stagingSubmitSuccess?: OptionalBoolean }
>;

export const StagingTab = (props: StagingTabProps) => {
    const { index, currentIndex, stagingSubmitSuccess } = props;

    const theme = useTheme();

    const formState = useFormState({
        fields: {
            clientName: {
                label: 'Client Name',
                initialValue: '' as OptionalString,
                required: false,
            },
            clientTypeId: {
                label: 'Client Type',
                initialValue: null as OptionalNumber,
                required: false,
            },
        },
        onSubmit: async () => {
            return { submitSucceeded: true };
        },
    });

    const [searchResults, setSearchResults] =
        useState<ClientSearchResultList | null>(null);
    const [dataGridIsLoading, setDataGridIsLoading] = useState(false);

    const loadNewResults = useCallback(async (request: ClientSearchRequest) => {
        setDataGridIsLoading(true);

        console.log('Retrieiving fake FmRun list...');

        const response = await getFakeClientList(request);

        console.log(`First result: ${response?.data[0].EINC}`);

        // TODO replace this in a future PR with something like:
        //      if(response.isSuccess)
        if (!!response) {
            setSearchResults(response);
        } else {
            alert('This will be an error message');
        }

        setDataGridIsLoading(false);
    }, []);

    // TODO replace this with an actual API call when the API is operational
    async function getFakeClientList(
        fmRunSearchRequest: ClientSearchRequest,
    ): Promise<ClientSearchResultList | null | undefined> {
        return new Promise(function (resolve, reject) {
            if (mockClientSearchResultList) {
                // Setting timeout to simulate loading from the API and display the built-in loading skeleton...
                setTimeout(() => {
                    resolve(
                        mockClientSearchResultList as ClientSearchResultList,
                    );
                }, 500);
            } else {
                setTimeout(() => {
                    reject('You should have never seen this' as OptionalString);
                }, 500);
            }
        });
    }

    // TODO replace this with useMutation from @tanstack/react-query
    useEffect(() => {
        loadNewResults(mockClientRequest);
    }, [mockClientRequest]);

    return (
        <BobTabContent index={index} currentIndex={currentIndex}>
            <Grid
                container
                size={12}
                sx={{
                    alignItems: 'center',
                    justifyItems: 'space-between',
                    height: theme.spacing(6),
                    pl: theme.spacing(0.5),
                }}
            >
                <Grid size={8}>
                    <Typography fontSize={20}>Select Clients</Typography>
                </Grid>
                <Grid size={4} sx={{ justifyItems: 'space-between' }}>
                    <Form
                        key="addCustomerFeeForm"
                        onSubmit={formState.submitForm}
                        validationMessage={formState.formValidationMessage}
                        sx={{ display: 'flex' }}
                    >
                        <BasicContainer>
                            <TextField
                                fieldState={formState.fields.clientName}
                                sx={{
                                    display: 'flex',
                                    px: theme.spacing(1),
                                }}
                                slotProps={{
                                    inputLabel: {
                                        sx: { px: theme.spacing(1) },
                                    },
                                }}
                            />
                        </BasicContainer>
                        <BasicContainer>
                            <ClientTypeDropdown
                                fieldState={formState.fields.clientTypeId}
                                sx={{
                                    display: 'flex',
                                    mx: theme.spacing(1),
                                }}
                            />
                        </BasicContainer>
                    </Form>
                </Grid>
            </Grid>
            <Box
                flex={1}
                sx={{
                    height: '100%',
                    width: '100%',
                    py: theme.spacing(3),
                    pr: theme.spacing(0.5),
                }}
            >
                {dataGridIsLoading ? (
                    <BasicContainer>
                        <Skeleton
                            variant="rectangular"
                            height={theme.spacing(10)}
                            sx={{ mb: theme.spacing(0.5) }}
                        />
                        <Skeleton
                            variant="rectangular"
                            height={theme.spacing(30)}
                        />
                    </BasicContainer>
                ) : (
                    <StagingClientListDataGrid
                        isLoading={dataGridIsLoading}
                        clientSearchResultList={searchResults}
                    />
                )}
            </Box>
            {!!stagingSubmitSuccess && (
                <Alert
                    severity="info"
                    sx={{
                        backgroundColor: bobColors.bobSecondary100.value,
                        color: 'black',
                        '.MuiAlert-icon': { color: 'black' },
                    }}
                >
                    Staging and verifying funding run in process...
                </Alert>
            )}
        </BobTabContent>
    );
};
