/* eslint-disable react/prop-types */
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import fuzzysort from 'fuzzysort';

import {
    Merge,
    PartialBy,
    PropsType,
    ThirdArgumentType,
} from '../../../../types/UtilityTypes';
import { FieldState } from '../../../../utils/formStateUtils';
import TextField from './TextField';
import { isTruthyOrZero } from '../../../../utils/numberUtils';
import FormattedValidationMessage from '../../form/FormattedValidationMessage';
import { Checkbox } from '@mui/material';
import { mergeRefs } from '../../../../utils/componentUtils';
import { ExpandMoreRoundedIcon } from '../../assets/ExpandMoreRoundedIcon';

export type DropdownFieldProps<
    TOption,
    TValue,
    Multiple extends boolean | undefined,
> = Merge<
    PartialBy<
        AutocompleteProps<TOption, Multiple, false, false>,
        'renderInput' | 'renderOption'
    >,
    {
        /**
         * If you provide the `fieldState` for a field in your form, this component
         * will use it to set default props for the underlying Material UI Autocomplete components.
         */
        fieldState?: FieldState<TValue>;

        /**
         * Used to determine the field value that each option represents.
         * If this function is omitted, then each option will be checked for a `value` property and that will be used
         * if it exists. Otherwise, the option object itself will be used as the field value, when selected.
         */
        getOptionValue?: (
            option: TOption,
        ) => TValue extends Array<infer Item> ? Item : TValue;
        label?: React.ReactNode;
        name?: string;
        value?: TValue;
        onChange?: (newValue: TValue) => void;
        required?: boolean;
        error?: boolean;
        helperText?: React.ReactNode;
        textFieldProps?: PropsType<typeof TextField>;
        autoFocus?: boolean;
        /**
         * If true, there will be a checkbox next to each option in the menu.
         * Use with `multiple={true}`
         */
        checkboxSelection?: boolean;
        /**
         * If `true`, the input search text will not be reset when an option is selected.
         * Useful if `multiple={true}` and you want the user to be able to type a search term
         * and select multiple options without re-typing the search term.
         */
        disableResetInputOnSelect?: boolean;
        /**
         * Customize how a dropdown option will be rendered.
         */
        renderOption?: (
            option: TOption,
            optionState: ThirdArgumentType<
                AutocompleteProps<
                    TOption,
                    Multiple,
                    false,
                    false
                >['renderOption']
            > & {
                label?: string;
                highlightedLabel?: React.ReactNode;
            },
        ) => React.ReactNode;
    }
>;

export type BasicDropdownOption<TValue> = {
    label?: string;
    value?: TValue;
    disabled?: boolean;
};

const FUZZYSORT_LABEL_KEY = 'FUZZYSORT_LABEL_KEY';

/**
 * A wrapper around the Material UI `Autocomplete` component.
 *
 * For the simplest usage, pass in the `fieldState` for a field in your form.
 * This component will use it to set default props for the underlying
 * Material UI `Autocomplete` component, so that it reflects the state of your field.
 * Feel free to override or expand upon those defaults by passing additional props to this component.
 *
 * This component expands upon the behavior of the MUI Autocomplete by allowing you to pass in
 * a `getOptionValue()` prop. This function allows you to specify the field value that is represented by
 * each option, when that option is selected. If you don't pass the `getOptionValue()` function, an option value
 * can stil be specified by the `value` property on each option object. If each option doesn't have
 * a `value` property, then the option object itself will be the field value when selected.
 *
 * For other usage, see the documentation for the Material UI Autocomplete:
 * - [Examples](https://mui.com/material-ui/react-autocomplete/)
 * - [Description of props you can pass](https://mui.com/material-ui/api/autocomplete/)
 */
function DropdownField<TOption, TValue, Multiple extends boolean | undefined>(
    _props: DropdownFieldProps<TOption, TValue, Multiple>,
    ref: React.ForwardedRef<unknown>,
) {
    const props = _props.fieldState ? mapFieldStateToProps(_props) : _props;

    // Convert `props.getOptionValue` and `props.getOptionLabel` to refs so that the memoization can work
    // for selectedOption and optionsForFuzzysort.
    // If the consumer defined `getOptionValue` or `getOptionLabel` as an inline literal prop to this component,
    // then it will be recreated every render. That would defeat the purpose of memoization.
    // So we'll take its value on the first render, and store it in an unchanging ref:
    const getOptionValueProp = useRef(props.getOptionValue).current;
    const getOptionLabelProp = useRef(props.getOptionLabel).current;

    const defaultIsOptionEqualToValue: typeof props.isOptionEqualToValue = (
        optionValue,
        value,
    ) => optionValue === value;
    const isOptionEqualToValue = useRef(
        props.isOptionEqualToValue || defaultIsOptionEqualToValue,
    ).current;

    /**
     * Determine the label of each option.
     * By default, we will look at the `.label` property of each option.
     * The consumer can override this by passing their own `getOptionLabel` function.
     */
    const defaultGetOptionLabel = useCallback(
        (option: TOption) =>
            typeof option === 'string'
                ? option
                : ((option as BasicDropdownOption<TValue>)?.label ??
                  '[Missing Label]'),
        [],
    );

    const getOptionLabel = getOptionLabelProp || defaultGetOptionLabel;

    /**
     * The `props.options` that the consumer has provided,
     * but with an additional property to support fuzzy searching.
     */
    const optionsForMuiAutocomplete = useMemo(
        () =>
            props.options.map((option) => {
                if (!isObject(option)) {
                    return option;
                }
                return {
                    ...option,
                    [FUZZYSORT_LABEL_KEY]: fuzzysort.prepare(
                        getOptionLabel(option),
                    ),
                };
            }),
        [getOptionLabel, props.options],
    );

    // The MUI Autocomplete component expects to use an option object as the selected value.
    // However, most of our forms just need a dropdown to select the id of an item in the list
    // so that the id can be submitted to an endpoint.
    // So in the Bob DropdownField component, we've added the ability for the user to specify
    // a `getOptionValue()` function, to specify a primitive value (like an id) for each option.
    // This id would then be used as the value of this field in the form.
    // We still need to pass an option object to MUI Autocomplete,
    // So we'll do that mapping here.
    // For the (likely primitive) field value,
    // We'll look up the option which is selected, and pass that into MUI Autocomplete as its `value`.
    const selectedOptions = useMemo(() => {
        // props.value is an array if this is a multi-select dropdown (`multiple={true}`)
        const currentValues = isArray(props.value)
            ? props.value
            : [props.value];

        return currentValues.map((value) => {
            return optionsForMuiAutocomplete.find((option) => {
                const optionValue = getOptionValue(option, getOptionValueProp);
                if (value === null || value === undefined) {
                    return optionValue === null;
                }
                return isOptionEqualToValue(optionValue as TOption, value);
            });
        });
    }, [
        optionsForMuiAutocomplete,
        getOptionValueProp,
        props.value,
        isOptionEqualToValue,
    ]);

    const selectedOption = props.multiple ? null : selectedOptions[0] || null;

    const hasValue = props.multiple
        ? !isEmpty(props.value)
        : getOptionValue(selectedOption, getOptionValueProp) !== null &&
          getOptionValue(selectedOption, getOptionValueProp) !== undefined &&
          getOptionValue(selectedOption, getOptionValueProp) !== '';

    const [_inputValue, setInputValue] = useState('');
    const inputValue = props.inputValue ?? _inputValue;

    const inputValueIsSelectedValue =
        selectedOption && inputValue === getOptionLabel(selectedOption);

    const inputRef = useRef(null as HTMLInputElement | null);

    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    name={props.name}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText}
                    autoFocus={props.autoFocus}
                    {...props.textFieldProps}
                    inputRef={mergeRefs(
                        inputRef,
                        props.textFieldProps?.inputRef,
                    )}
                />
            )}
            // Determine if an option should be disabled.
            // By default, look at the `.disabled` property of each option
            // The consumer can override this by passing their own `getOptionDisabled` function:
            getOptionDisabled={(option) =>
                !!(option as BasicDropdownOption<TValue>)?.disabled
            }
            filterSelectedOptions={props.multiple && !props.checkboxSelection}
            disableClearable={!hasValue}
            filterOptions={(options, state) => {
                if (!state.inputValue || !options?.length) {
                    /*
                        We are only going to show the first 50 results. 
                        More than that, and things start slowing down on the page.
                        When users type into the box, it will still search all results 
                    */
                    return options.slice(0, 50);
                }

                if (typeof options[0] === 'string') {
                    const fuzzysortResults = fuzzysort.go(
                        state.inputValue,
                        options as Array<string>,
                        {
                            limit: 50,
                        },
                    );
                    return (fuzzysortResults?.map((r) => r.target) ??
                        []) as typeof options;
                }

                // The options are objects
                const fuzzysortResults = fuzzysort.go(
                    state.inputValue,
                    options,
                    {
                        key: FUZZYSORT_LABEL_KEY,
                        limit: 50,
                    },
                );
                return fuzzysortResults?.map((r) => r.obj) ?? [];
            }}
            inputValue={_inputValue}
            onInputChange={(event, newInputValue, reason) => {
                if (
                    props.disableResetInputOnSelect &&
                    reason === 'reset' &&
                    document.activeElement === inputRef.current
                ) {
                    // The field is focused and the user just selected an item.
                    // Let's avoid calling `setDropdownSearchText()` so that the search text won't be cleared.
                    // Instead, we can just select the search text in case the user wants to type something new:
                    inputRef.current?.select();
                } else {
                    // Normal situation, let's update the search text state with the new value:
                    setInputValue(newInputValue);
                }
            }}
            disableCloseOnSelect={props.checkboxSelection}
            // Now, pass all of the props that the consumer has supplied to the Autocomplete.
            // These will override any props of the same name above this point:
            {...getPropsForAutocomplete(props)}
            // Now, we will provide a couple more props that the consumer cannot override:
            options={optionsForMuiAutocomplete}
            value={props.multiple ? selectedOptions : selectedOption}
            onChange={(event, newValue) => {
                if (!props.onChange) {
                    return;
                }

                // MUI Autocomplete will have given us an option object as the `newValue`
                // (or an array of option objects when `multiple={true}`).
                // In the Bob DropdownField, we've decided to make it easy to map this to a primitive field value
                // so let's propagate that primitive value if the user has supplied a `getOptionValue` function:
                let valueToPropagate: TValue;

                if (isArray(newValue)) {
                    valueToPropagate = newValue.map((option) =>
                        getOptionValue(option, props.getOptionValue),
                    ) as TValue;
                } else if (isTruthyOrZero(newValue)) {
                    valueToPropagate = getOptionValue(
                        newValue,
                        props.getOptionValue,
                    ) as TValue;
                } else {
                    valueToPropagate = newValue as TValue;
                }

                props.onChange(valueToPropagate);
            }}
            getOptionLabel={getOptionLabel}
            renderOption={(listItemProps, option, optionState) => {
                const optionLabel = getOptionLabel(option as TOption);

                let highlightedLabel: React.ReactNode = optionLabel;
                if (!inputValueIsSelectedValue) {
                    const fuzzysortItem = fuzzysort.single(
                        optionState.inputValue,
                        optionLabel.replace(/ /g, '\u00A0'),
                    );
                    if (fuzzysortItem) {
                        highlightedLabel = fuzzysortItem.highlight(
                            (matchingText, i) => (
                                <strong key={i}>{matchingText}</strong>
                            ),
                        );
                    }
                }

                let optionContent: React.ReactNode =
                    highlightedLabel || optionLabel;

                if (props.renderOption) {
                    optionContent = props.renderOption(option as TOption, {
                        ...optionState,
                        label: optionLabel,
                        highlightedLabel,
                    });
                }

                return (
                    <li {...listItemProps}>
                        {props.checkboxSelection && (
                            <Checkbox
                                icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={optionState.selected}
                            />
                        )}
                        {optionContent}
                    </li>
                );
            }}
            popupIcon={<ExpandMoreRoundedIcon />}
            ref={ref}
        />
    );
}

/**
 * This function returns the props to pass to the MUI Autocomplete component.
 * It will exclude any props that are unique to the Bob DropdownField component.
 */
const getPropsForAutocomplete = <
    TOption,
    TValue,
    Multiple extends boolean | undefined,
>(
    props: DropdownFieldProps<TOption, TValue, Multiple>,
) => {
    const {
        fieldState,
        getOptionValue,
        value,
        label,
        name,
        required,
        error,
        helperText,
        textFieldProps,
        autoFocus,
        checkboxSelection,
        disableResetInputOnSelect,
        ...propsForAutocomplete
    } = props;
    return propsForAutocomplete as PartialBy<
        AutocompleteProps<TOption, boolean, false, false>,
        'renderInput' | 'value'
    >;
};

/**
 * For a given dropdown option, this function will determine the field value that it represents
 * (or if `multiple=true`, the value array element that it represents)
 */
const getOptionValue = <TOption, TValue>(
    option: TOption,
    getOptionValueProp?: (
        option: TOption,
    ) => TValue extends Array<infer Item> ? Item : TValue,
) => {
    // If the consumer has specified how to get the value for an option, let's use their function:
    if (getOptionValueProp) {
        return getOptionValueProp(option);
    }

    // The consumer hasn't specified how to get the value of an option.
    // By default, we'll look for a `.value` property on the option:
    if ((option as BasicDropdownOption<TValue>)?.value !== undefined) {
        return (option as BasicDropdownOption<TValue>).value;
    }

    // We're not sure how to get the value of an option.
    // Let's fall back to just using the option itself as the selected value:
    return option;
};

/**
 * If the consumer has provided a `fieldState` variable from their form (See `formUtils.ts`),
 * this function will map it to the correct props for this `DropdownField` component.
 */
const mapFieldStateToProps = <
    TOption,
    TValue,
    Multiple extends boolean | undefined,
>(
    allProps: DropdownFieldProps<TOption, TValue, Multiple>,
): DropdownFieldProps<TOption, TValue, Multiple> => {
    const { fieldState, ...props } = allProps;
    if (!fieldState) return props;

    return {
        label: fieldState.label,
        name: fieldState.name,
        value: fieldState.value,
        onChange: (newValue) => {
            fieldState.setValue(newValue);
        },
        required: fieldState.required,
        error: fieldState.isNotValid,
        helperText: (
            <FormattedValidationMessage
                validationMessage={fieldState.validationMessage}
            />
        ),
        multiple: isArray(fieldState.value) as Multiple,
        ...props,
        disabled: fieldState.formIsSubmitting || props.disabled,
    };
};

export default forwardRef(DropdownField);
