import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { FmRunSearchResultList } from '../../../api/fmRun/fmRun';
import { useMemo, useState } from 'react';
import {
    GridColDef,
    GridRowSelectionModel,
    GridValidRowModel,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { Radio } from '@mui/material';
import { OptionalNumber } from '../../../types/UtilityTypes';

export type FmRunGridProps = {
    isLoading: boolean;
    fmRunSearchResultList: FmRunSearchResultList | null | undefined;
    onFmRunSelectionChange: (newSelection: OptionalNumber) => void;
};

export const FmRunGrid = (props: FmRunGridProps) => {
    const { isLoading, fmRunSearchResultList, onFmRunSelectionChange } = props;
    const resultRows = useMemo(
        () =>
            fmRunSearchResultList?.data?.map((fmRun) => ({
                ...fmRun,
                id: fmRun.FmRunId,
                isNew: false,
            })) ?? [],
        [fmRunSearchResultList?.data],
    );

    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>([]);

    const columns = useMemo(
        (): GridColDef<GridValidRowModel>[] => [
            {
                field: 'Selected',
                headerName: '',
                width: 40,
                renderCell: (cell) => {
                    return (
                        <Radio
                            checked={cell.id === rowSelectionModel[0]}
                            value={cell.id}
                            color="primary"
                        />
                    );
                },
            },
            {
                field: 'FmRunId',
                headerName: 'Fm Run Id',
                type: 'number',
            },
            {
                field: 'HierId',
                headerName: 'Hier Id',
                type: 'number',
            },
            {
                field: 'WeekendBill',
                headerName: 'Weekend Bill',
                type: 'date',
                valueFormatter: (value) =>
                    value ? DateTime.fromISO(value).toLocaleString() : '',
            },
            {
                field: 'GlPostDate',
                headerName: 'GL Post Date',
                type: 'date',
                valueFormatter: (value) =>
                    value
                        ? DateTime.fromISO(value).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                          )
                        : '',
            },
            {
                field: 'EINC',
                headerName: 'EINC',
                type: 'number',
            },
            {
                field: 'CompanyFullName',
                headerName: 'Employer',
                type: 'string',
            },
            {
                field: 'DateCreated',
                headerName: 'Date Created',
                type: 'date',
                valueFormatter: (value) =>
                    value
                        ? DateTime.fromISO(value).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                          )
                        : '',
            },
            {
                field: 'DateAbandoned',
                headerName: 'Date Abandoned',
                type: 'date',
                valueFormatter: (value) =>
                    value
                        ? DateTime.fromISO(value).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                          )
                        : '',
            },
            {
                field: 'RunType',
                headerName: 'Run Type',
                type: 'string',
            },
            {
                field: 'DateRunStart',
                headerName: 'Run Start',
                type: 'date',
                valueFormatter: (value) =>
                    value
                        ? DateTime.fromISO(value).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                          )
                        : '',
            },
            {
                field: 'DateRunComplete',
                headerName: 'Run Complete',
                type: 'date',
                valueFormatter: (value) =>
                    value
                        ? DateTime.fromISO(value).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                          )
                        : '',
            },
            {
                field: 'ItemCount',
                headerName: 'Item Count',
                type: 'number',
            },
            {
                field: 'SumOfItems',
                headerName: 'Sum Of Items',
                type: 'number',
            },
        ],
        [resultRows, rowSelectionModel],
    );

    function handleChangeSelection(
        newRowSelectionModel: GridRowSelectionModel,
    ) {
        const fmRunSelection = resultRows.find(
            (row) => row.id === newRowSelectionModel[0],
        )?.FmRunId;
        onFmRunSelectionChange(fmRunSelection);
        setRowSelectionModel(newRowSelectionModel);
    }

    return (
        <DataGrid
            autosizeOnMount
            columns={columns}
            rows={resultRows}
            loading={isLoading}
            disableMultipleRowSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
                handleChangeSelection(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
        ></DataGrid>
    );
};
