import { createTheme } from '@mui/material/styles';
import { bobColors } from './colors';
import '@fontsource/inter';
import getThemeComponentDefaults from './themeComponentDefaults';

export function CreateBobTheme() {
    const bobTheme = createTheme({
        palette: {
            primary: {
                main: bobColors.bobPrimary100.value,
                light: bobColors.bobPrimary50.value,
                contrastText: '#FFF',
            },
            secondary: {
                main: bobColors.bobSecondary100.value,
            },
            text: {
                // The MUI default for secondary text (including form labels) is rgba(0, 0, 0, 0.54).
                // We're going to make is slightly darker for ADA compliance.
                // This will especially help with readability of form labels on "filled" inputs:
                secondary: 'rgba(0, 0, 0, 0.60)',
            },
            background: {
                default: bobColors.bobBackground.value,
            },
        },
        typography: {
            fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
        },
        spacing: (factor) => `${0.5 * factor}rem`,
    });

    return createTheme(bobTheme, {
        components: getThemeComponentDefaults(bobTheme),
    });
}
