import { OptionalString } from '../types/UtilityTypes';
import {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@mui/material/colors';

export const muiColors = {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
};

export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type ColorValue = RGB | RGBA | HEX;

export type BobColor = {
    name: string;
    description?: OptionalString;
    value: ColorValue;
};

const bobPrimary100: BobColor = {
    name: 'Primary 100%',
    description: 'The main color for the app',
    value: '#00838C',
};

const bobPrimary50: BobColor = {
    name: 'Primary 50%',
    description: 'The main color at 50% saturation',
    value: '#80C1C5',
};

const bobSecondary100: BobColor = {
    name: 'Secondary 100%',
    description: 'The secondary color for the app',
    value: '#DBF4EE',
};

const bobBackground: BobColor = {
    name: 'Background',
    description: 'The color of the web app background',
    value: '#fbfbfb',
};

const coolGrey100: BobColor = {
    name: 'Cool Grey',
    value: '#616565',
};
const coolGrey80: BobColor = {
    name: 'Cool Grey 80%',
    value: '#757979',
};
const coolGrey60: BobColor = {
    name: 'Cool Grey 60%',
    value: '#9ea2a2',
};
const coolGrey40: BobColor = {
    name: 'Cool Grey 40%',
    value: '#bcc1c1',
};
const coolGrey20: BobColor = {
    name: 'Cool Grey 20%',
    value: '#dfe4e4',
};

const black100: BobColor = {
    name: 'Black 100%',
    value: '#000000',
};

const black80: BobColor = {
    name: 'Black 80%',
    value: '#262626',
};

const black60: BobColor = {
    name: 'Black 60%',
    value: '#7b7b7b',
};

const black40: BobColor = {
    name: 'Black 40%',
    value: '#a5a5a5',
};

const black20: BobColor = {
    name: 'Black 20%',
    value: '#c3c3c3',
};

const greyFalse: BobColor = {
    name: 'Grey False',
    description: 'Grey used for false values',
    value: '#737373',
};

const white100: BobColor = {
    name: 'White',
    description: 'The one and only white',
    value: '#FFFFFF',
};

const success100: BobColor = {
    name: 'Success 100%',
    description: 'Displayed when the user accomplishes a task',
    value: '#4CAF50',
};

const successMuted: BobColor = {
    name: 'Success Muted',
    description: 'Success but less vibrant',
    value: '#E0EEDC',
};

const error100: BobColor = {
    name: 'Error 100%',
    description: 'Displayed when something is wrong',
    value: '#F44336',
};

const errorMuted: BobColor = {
    name: 'Error Muted',
    description: 'Error but less vibrant',
    value: '#EEC6C2',
};

const warningMuted: BobColor = {
    name: 'Warning Muted',
    description: 'Warning but less vibrant',
    value: '#F5EAB3',
};

const infoMuted: BobColor = {
    name: 'Skip Muted',
    description: 'Skip but less vibrant',
    value: '#CFE5FE',
};

const purpleStatusMuted: BobColor = {
    name: 'Purple Status Muted',
    description: 'Purple but muted',
    value: '#EEDFF',
};

const greyStatusMuted: BobColor = {
    name: 'Grey status muted',
    description: 'Grey but muted',
    value: '#DFDFDF',
};

export const bobColors = {
    bobPrimary100,
    bobPrimary50,
    bobSecondary100,
    bobBackground,
    coolGrey100,
    coolGrey80,
    coolGrey60,
    coolGrey40,
    coolGrey20,
    black100,
    black80,
    black60,
    black40,
    black20,
    greyFalse,
    white100,
    success100,
    successMuted,
    error100,
    errorMuted,
    warningMuted,
    infoMuted,
};
