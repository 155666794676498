import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import BobThemeProvider from '../styles/bobThemeProvider';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NavMenu from './NavMenu';
import { Paper } from '@mui/material';
import { bobColors } from '../styles/colors';
import { BasicContainer } from './common/containers/BasicContainer';

interface AppLayoutContainerProps {
    children?: any;
}

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        paddingX: 2,
        overflowX: 'hidden',
        whiteSpace: 'normal',
        height: '100%',
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(32),
        },
        [theme.breakpoints.down('lg')]: {
            width: theme.spacing(28),
        },
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(24),
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
        },
        boxSizing: 'border-box',
        ...(!open && {
            [theme.breakpoints.up('lg')]: {
                width: theme.spacing(14),
            },
            [theme.breakpoints.down('lg')]: {
                width: theme.spacing(12),
            },
            [theme.breakpoints.down('md')]: {
                width: theme.spacing(10),
            },
            [theme.breakpoints.down('sm')]: {
                width: theme.spacing(8),
            },
        }),
    },
}));

const AppLayoutContainer = (props: AppLayoutContainerProps) => {
    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const { children } = props;

    const theme = useTheme();

    return (
        <BobThemeProvider>
            <Paper
                className="mainContainer"
                component={'main'}
                elevation={9}
                sx={{
                    backgroundColor: bobColors.bobBackground.value,
                    display: 'flex',
                    height: '100%',
                }}
            >
                <CssBaseline />
                <Paper elevation={1}>
                    <Drawer variant="permanent" anchor="left" open={open}>
                        <Box sx={{}}>
                            <NavMenu
                                drawerOpen={open}
                                toggleDrawer={toggleDrawer}
                            />
                        </Box>
                    </Drawer>
                </Paper>

                <BasicContainer
                    className="contentContainer"
                    sx={{
                        display: 'flex',
                        my: theme.spacing(6.5),
                    }}
                >
                    {children}
                </BasicContainer>
            </Paper>
        </BobThemeProvider>
    );
};

export default AppLayoutContainer;
